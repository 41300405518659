import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Trans, withI18n } from '@lingui/react';
import { prefix } from '../i18n-config';

function NotFoundPage(props) {
  const { pageContext, data } = props;
  const langPrefix = prefix(pageContext.lang);
  return (
    <div className="page page--404">
      <h1 className="page__title"><Trans>Page not found.</Trans></h1>
    </div>
  );
}

export default withI18n()(NotFoundPage);